const title = 'Export data to CSV file';

const config = (() => {

  return Object.freeze({
    title,
    isEnabled: true,
  });
})();

export default config;
